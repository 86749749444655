<template>
    <v-container class="orange lighten-5 mt-3">
        <v-row dense>
            <v-col cols="12">
                <h2>DETALLE COMPRA</h2>
            </v-col>
            <v-col cols="12" class="justify-center align-center">
                <form @submit.prevent="addItemCompra">
                    <v-row class="justify-center align-center" dense>
                        <v-col cols="3">
                            <v-text-field
                                v-if="listProducto"
                                v-validate="'required'"
                                data-vv-name="formItem.description"
                                data-vv-as="Item"
                                placeholder="1/4 Clavos"
                                required
                                v-model="formItem.description"
                                label="Agregar Item"
                            />

                            <v-autocomplete
                                v-else
                                v-model="idOrdenCompra"
                                v-validate="'required'"
                                label="--Producto"
                                :items="_listaProductoCompra"
                                required
                                item-text="rename"
                                item-value="id"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2"
                            ><v-text-field
                                v-validate="'required'"
                                data-vv-name="formItem.unidades"
                                data-vv-as="Monto"
                                placeholder="1"
                                required
                                v-model="formItem.unidades"
                                label="Unidades"
                                @keypress="isNumber"
                        /></v-col>
                        <v-col cols="2">
                            <v-autocomplete
                                v-model="formItem.idTipoMoneda"
                                item-text="value"
                                item-value="id"
                                :items="tipoMoneda"
                                label="Tipo Moneda del Producto"
                                v-validate="'required'"
                                :error-messages="errors.collect('formItem.idTipoMoneda')"
                                data-vv-name="formItem.idTipoMoneda'"
                                data-vv-as="Tipo Moneda"
                                required
                            />
                        </v-col>
                        <v-col cols="3"
                            ><v-text-field
                                v-validate="'required'"
                                data-vv-name="formItem.importe"
                                data-vv-as="Monto"
                                placeholder="0.50"
                                required
                                v-model="formItem.importe"
                                label="Precio Unitario"
                                @keypress="isNumber"
                        /></v-col>
                        <!--                         <v-col cols="2" v-if="formItem.idTipoMoneda == 2"
                            ><v-text-field
                                dense
                                v-validate="'required'"
                                data-vv-name="formItem.tipoCambio"
                                data-vv-as="tipoCambio"
                                required
                                v-model="formItem.tipoCambio"
                                label="Tipo Cambio"
                                @keypress="isNumber"
                                class="required"
                        /></v-col> -->
                        <v-col cols="2">
                        <v-btn type="submit" dark small>Agregar</v-btn>&nbsp;
                        <v-btn @click="btn" class="blue" dark small>Activar Productos</v-btn></v-col
                        >
                    </v-row>
                </form>
            </v-col>
            <v-col cols="12" class="justify-center align-center">
                <v-data-table multi-sort dense :headers="headers" :items="itemsCompra" :items-per-page="10" class="elevation-1">
                    <template v-slot:[`item.action`]="{ item }">
                        <v-icon color="primary" small @click="deleteItem(item)">mdi-delete</v-icon>
                    </template>
                    <template v-slot:item.idTipoMoneda="{ item }">
                        {{ getText(item.idTipoMoneda) }}
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="12" v-if="itemsCompra.length" class="justify-center align-center">
                <v-row class="white subtitle lighten-3 text-sm-left">
                    <v-col cols="12" sm="6" offset-sm="6">
                        <v-row>
                            <v-col cols="6" v-if="tipoComprobante === 1"
                                >IGV : {{ getText(itemsCompra[0].idTipoMoneda, 'text') }} <strong> {{ resultado.igv | formatPrice }}</strong></v-col
                            >
                            <v-col cols="6" class="text-right"
                                >Importe Total: {{ getText(itemsCompra[0].idTipoMoneda, 'text') }}
                                <strong> {{ resultado.importeTotal | formatPrice }}</strong></v-col
                            >
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mixins } from '@/mixins/mixin.js'
import DefaultForm from '@/utils/defaultForm'
import { mapState } from 'vuex'

export default {
    mixins: [mixins],
    filters: {
        text(val) {
            return val ? val.slice(0, 8).concat('~') : ''
        },
        formatPrice(val) {
            return new Intl.NumberFormat('en-US').format(val)
        }
    },
    props: ['tipoComprobante', 'itemsCompra'],
    computed: {
        ...mapState('productos', ['dataproducto']),
        ...mapState('utilities', ['tipoMoneda']),
        resultado() {
            let importeTotal = 0
            let igv = 0

            if (this.itemsCompra.length > 0) {
                this.itemsCompra.map((x) => (importeTotal = parseFloat(importeTotal) + parseFloat(x.importe) * parseFloat(x.unidades)))
            }
            let subTotal = importeTotal / 1.18
            igv = importeTotal - subTotal
            return {
                importeTotal,
                igv
            }
        },

        _listaProductoCompra() {
            let listaProductos = []
            this.dataproducto.map((v) => {
                const tMoneda = v.idTipoMoneda == 1 ? 'S/' : '$'

                const body = {
                    ...v,
                    rename: ` ${tMoneda} ${v.price} | ${v.description} | ${v.model} | ${v.internal_id}`
                }
                listaProductos.push(body)
            })
            return listaProductos
        }
    },
    data() {
        return {
            idOrdenCompra: null,
            listProducto: true,
            recibido: null,
            servicio: DefaultForm.formSelectServicio(),
            headers: [
                { text: 'Actions', value: 'action', sortable: false },
                { text: 'Descripción', value: 'description' },
                { text: 'Moneda', value: 'idTipoMoneda' },
                { text: 'Unidades', value: 'unidades' },
                { text: 'Precio Unitario', value: 'importe' },
                { text: 'Precio Total', value: 'importeTotal' }
            ],
            formItem: DefaultForm.formItemCompra()
        }
    },
    async mounted() {
        await this.$store.dispatch('productos/products', { active: 0 })
    },

    methods: {
        getText(val, tipo) {
            if (val) {
                const item = this.tipoMoneda.find((x) => x.id == val)
                return tipo ? item[tipo] : item.value
            } else {
                return ''
            }
        },
        addItemCompra() {
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    // this.itemsCompra.push(this.formItem)

                    this.itemsCompra.push({
                        ...this.formItem,
                        importe: Number(this.formItem.importe).toFixed(2),
                        importeTotal: Number(Number(this.formItem.unidades) * this.formItem.importe).toFixed(2),
                        description: typeof this.formItem.description === 'object' ? this.formItem.description.description : this.formItem.description
                    })
                    console.log('this.itemsCompra', this.itemsCompra)
                    this.formItem = DefaultForm.formItemCompra()
                    this.$emit('child-itemsCompra', this.itemsCompra)
                }
                this.idOrdenCompra = null
            })
        },
        btn() {
            this.listProducto = this.listProducto === true ? false : true
            this.formItem.description = null
            this.formItem.importe = null
        },
        async deleteItem(item) {
            const index = this.itemsCompra.indexOf(item)
            this.itemsCompra.splice(index, 1)
            this.$emit('child-itemsCompra', this.itemsCompra)
        }
    },
    watch: {
        idOrdenCompra(val) {
            if (val) {
                const isFind = this._listaProductoCompra.find((x) => x.id == val)
                this.formItem.importe = isFind.purchase_unit_price
                this.formItem.description = isFind.description
                this.formItem.idTipoMoneda = isFind.idTipoMoneda
            }
        }
    }
}
</script>

<template>
    <v-container class="blue lighten-5 mt-3">
        <v-row dense>
            <v-col cols="12">
                <h2>DETALLE PAGOS</h2>
            </v-col>
            <v-col cols="12" class="justify-center align-center">
                <form @submit.prevent="addItemPago">
                    <v-container>
                        <v-row class="align-center">
                            <v-col cols="3" class="">
                                <v-autocomplete
                                    v-validate="'required'"
                                    :error-messages="errors.collect('formItem.idTipoPago')"
                                    data-vv-name="formItem.idTipoPago'"
                                    data-vv-as="TipoPago"
                                    required
                                    class="required"
                                    dense
                                    v-model="formItem.idTipoPago"
                                    item-text="text"
                                    item-value="id"
                                    :items="paymentMethod"
                                    label="Forma de Pago"
                                />
                            </v-col>
                            <!-- :disabled="disableTc" -->

                            <v-col cols="4">
                                <v-autocomplete
                                    class="required"
                                    dense
                                    v-model="formItem.idTipoMoneda"
                                    item-text="value"
                                    item-value="id"
                                    :items="tipoMoneda"
                                    label="Tipo Moneda"
                                    v-validate="'required'"
                                    :error-messages="errors.collect('formItem.idTipoMoneda')"
                                    data-vv-name="formItem.idTipoMoneda'"
                                    data-vv-as="Tipo Moneda"
                                    required
                                />
                            </v-col>
                            <v-col cols="4"
                                ><v-text-field
                                    dense
                                    v-validate="'required'"
                                    data-vv-name="formItem.importe"
                                    data-vv-as="importe"
                                    class="required"
                                    required
                                    v-model="formItem.importe"
                                    label="Importe Pagado"
                                    @keypress="isNumber"
                                    :error-messages="errors.collect('formItem.importe')"
                            /></v-col>

                            <v-col cols="2" v-if="formItem.idTipoMoneda == 2">
                                <v-text-field
                                    dense
                                    v-validate="'required'"
                                    data-vv-name="formItem.tipoCambio"
                                    data-vv-as="tipoCambio"
                                    required
                                    v-model="formItem.tipoCambio"
                                    label="Tipo Cambio"
                                    @keypress="isNumber"
                                    class="required"
                            /></v-col>

                            <v-col cols="2">
                                <v-menu
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            dense
                                            v-model="formItem.fechaPago"
                                            label="Fecha de Pago"
                                            append-icon="mdi-calendar"
                                            readonly
                                            data-vv-name="formItem.fechaPago"
                                            data-vv-as="fecha"
                                            :error-messages="errors.collect('formItem.fechaPago')"
                                            v-on="on"
                                        />
                                    </template>
                                    <v-date-picker v-model="formItem.fechaPago" no-title locale="es" @input="menu = false" /> </v-menu
                            ></v-col>

                            <v-col cols="2" align-self="start" class="text-center"><v-btn type="submit" dark small>Agregar</v-btn></v-col>
                        </v-row></v-container
                    >
                </form>
            </v-col>

            <v-col cols="12" class="justify-center align-center">
                <v-data-table multi-sort dense :loading="loading" :headers="headers" :items="_listaPagos" :items-per-page="10" class="elevation-1">
                    <template v-slot:[`item.action`]="{ item }">
                        <v-icon color="primary" small @click="deleteItem(item)">mdi-delete</v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mixins } from '@/mixins/mixin.js'
import DefaultForm from '@/utils/defaultForm'
import { mapState } from 'vuex'
import { format } from 'date-fns'

export default {
    mixins: [mixins],
    props: ['loading'],
    filters: {
        text(val) {
            return val ? val.slice(0, 8).concat('~') : ''
        },
        formatPrice(val) {
            return new Intl.NumberFormat('en-US').format(val)
        }
    },
    computed: {
        ...mapState('compras', ['datos', 'listaPagos']),
        ...mapState('pagos', ['external_tipoCambio']),
        ...mapState('utilities', ['tipoDocumento', 'tipoMoneda', 'paymentMethod']),
        _listaPagos() {
            let items = []

            if (this.listaPagos.length > 0) {
                this.listaPagos.map((x) => {
                    //console.log('x ', x)
                    const { text: tipoPago } = this.paymentMethod.find((y) => y.id == x.idTipoPago)
                    const { text: _tipoMoneda } = this.tipoMoneda.find((y) => y.id == x.idTipoMoneda)

                    items.push({
                        ...x,
                        tipoPago: tipoPago,
                        tipoMoneda: _tipoMoneda
                    })
                })
            }
            return items
        },
        idTipoMonedaVenta() {
            let item = this.datos.idTipoMoneda
            if (item === 1) {
                this.formItem.idTipoMoneda = 1
            } else {
            }
            return item
        },
        resultado() {
            let importeTotal = 0
            let igv = 0

            if (this.listaPagos.length > 0) {
                this.listaPagos.map((x) => (importeTotal = parseFloat(importeTotal) + parseFloat(x.importe)))
            }
            let subTotal = importeTotal / 1.18
            igv = importeTotal - subTotal
            return {
                importeTotal,
                igv
            }
        },
        _idTipoMoneda() {
            return this.formItem.idTipoMoneda
        },
        idTipoMonedaVenta() {
            return this.datos.idTipoMoneda
        }
    },
    data() {
        return {
            disableTc: false,
            menu: false,
            recibido: null,
            headers: [
                { text: 'Actions', value: 'action', sortable: false },
                { text: 'TipoPago', value: 'tipoPago' },
                { text: 'TipoMoneda', value: 'tipoMoneda' },
                { text: 'Importe', value: 'importe' },
                { text: 'TipoCambio', value: 'tipoCambio' },
                { text: 'FechaPago', value: 'fechaPago' }
            ],
            formItem: DefaultForm.formItemPago()
        }
    },
    methods: {
        addItemPago() {
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    this.$store.commit('mainUI/OPEN_MODAL', {
                        state: true,
                        text: 'guardando pago'
                    })
                    const body = {
                        ...this.formItem,
                        idTipoMonedaVenta: this.idTipoMonedaVenta
                    }
                    //console.log('this.formItem ', body)
                    await this.$store.dispatch('compras/postPagos', body)
                    this.$store.commit('mainUI/OPEN_MODAL', { state: false })
                    this.formItem = DefaultForm.formItemPago()

                    /*                     this.listaPagos.push(this.formItem)
                    this.$emit('child-listaPagos', this.listaPagos) */
                }
            })
        },
        async deleteItem(item) {
            this.$store.commit('mainUI/OPEN_MODAL', {
                state: true,
                text: 'Eliminando pago'
            })
            await this.$store.dispatch('compras/deletePagos', item)
            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
        }
    },
    watch: {
        _idTipoMoneda(val) {
            this.formItem.tipoCambio = this.external_tipoCambio
        },
        idTipoMonedaVenta(val) {
            //console.log('this.datos.idTipoMoneda', val)
            const idTipoMonedaVenta = this.datos.idTipoMoneda
            if (idTipoMonedaVenta === 1) {
                this.disableTc = true
                this.formItem.idTipoMoneda = 1
            } else {
                this.disableTc = false
                this.formItem.idTipoMoneda = 2
            }
        }
    }
}
</script>

<template>
    <v-container>
        <!-- <form slot="leftContainer" id="tagGastos" autocomplete="nope" data-vv-scope="form-2" @submit.prevent="addGastos('form-2')">
            <v-card v-if="checkbox">
                <v-card-title class="grey lighten-4">
                    <b>REGISTRAR ARCHIVOS DE COMPRA</b>
                </v-card-title>
                <v-card-text>
                    <v-row dense align="center">
                        <v-col cols="12" sm="8" class="pt-5 mt-5">
                            <v-file-input accept=".xml" outlined v-model="file" small-chips multiple label="DOCUMENTOS DE COMPRA"></v-file-input>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-btn large :loading="loadingFile" color="success" dark small @click="upload">
                                Upload
                                <v-icon right dark>mdi-cloud-upload</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card>
                <v-card-title class="grey lighten-4">
                    <b>AGREGAR GASTOS</b>
                </v-card-title>
                <v-card-text>
                    <v-row dense align="center">
                        <v-col cols="12" sm="4">
                            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="datos.fechaEmision"
                                        label="Fecha de Emisión"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        data-vv-name="datos.fechaEmision"
                                        data-vv-as="fecha"
                                        :error-messages="errors.collect('datos.fechaEmision')"
                                        v-on="on"
                                    />
                                </template>
                                <v-date-picker v-model="datos.fechaEmision" no-title locale="es" @input="menu = false" />
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-autocomplete
                                v-model="datos.id_tipo_gasto"
                                v-validate="'required'"
                                :items="listaCategoria"
                                item-text="tipo_gasto"
                                placeholder="LUZ"
                                item-value="id_tipo_gasto"
                                :error-messages="errors.collect('datos.id_tipo_gasto')"
                                label="Categoría"
                                data-vv-name="datos.id_tipo_gasto"
                                data-vv-as="Tipo"
                                required
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-autocomplete
                                v-model="datos.idTipoComprobante"
                                v-validate="'required'"
                                :items="tipoComprobante"
                                item-text="descripcion"
                                placeholder="Contado"
                                item-value="id"
                                :error-messages="errors.collect('datos.idTipoComprobante')"
                                label="Tipo Comprobante"
                                data-vv-name="datos.idTipoComprobante"
                                data-vv-as="Tipo"
                                required
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="datos.numeroComprobante"
                                v-validate="'required'"
                                data-vv-name="datos.numeroComprobante"
                                data-vv-as="Numero Comprobante"
                                :error-messages="errors.collect('datos.numeroComprobante')"
                                label="N°  Documento"
                                placeholder="F001-0011"
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field v-model="datos.ordenCompra" label="N° Orden de Compra (Opcional)" />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-checkbox v-model="checkbox" :label="`Registrar Compra Masiva XML`"></v-checkbox>
                        </v-col>
                    </v-row>
                    <!-- PROVEEDOR -->
                    <!-- <v-row dense align="center">
                        <v-col cols="12" sm="5">
                           v-validate="'required'" -->
                            <!-- <v-autocomplete
                                v-validate="'required'"
                                v-model="datos.idProveedor"
                                :items="listaProveedor"
                                item-text="name"
                                placeholder="INNOUT SAC"
                                item-value="id"
                                :error-messages="errors.collect('datos.idProveedor')"
                                label="Buscar Proveedor"
                                data-vv-name="datos.idProveedor"
                                data-vv-as="Tipo"
                                filled
                                rounded
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-btn dark small @click="openModalProveedor = true">
                                <b>Agregar Proveedor</b>
                            </v-btn>
                        </v-col> -->
                    <!-- </v-row>
                    <ItemGastos
                        :itemsCompra="itemsCompra"
                        :items-per-page="20"
                        @child-itemsCompra="fnItemsCompra"
                        :tipoComprobante="datos.idTipoComprobante"
                    ></ItemGastos>
                    <ItemPagos></ItemPagos>
                    <v-col cols="12" sm="2" class="mt-2">
                        <v-btn type="submit" large :loading="loading" dark :color="step == 1 ? 'primary' : 'orange'" block>
                            <b>{{ step == '1' ? 'Agregar COMPRA' : 'Actualizar' }}</b>
                        </v-btn>
                    </v-col>
                </v-card-text>
            </v-card>
        </form> --> 
        <v-card slot="rightContainer" outlined>
            <v-card-title class="grey lighten-4">
                <b>HISTORICO GASTO 3 MESES</b>
                <v-spacer />
                <v-col cols="12" sm="3">
                    <v-menu
                        ref="menu"
                        v-model="menu3"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                    >
                        <template #activator="{ on, attrs }">
                            <v-text-field
                                v-model="fechaFiltro"
                                hide-details="auto"
                                dense
                                label="FILTRAR POR RANGO - MES"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="fechaFiltro" range no-title scrollable>
                            <v-spacer />
                            <v-btn text color="primary" @click="menu3 = false"> CERRAR </v-btn>
                            <v-btn color="primary" :loading="loadingLista" @click="getLista"> BUSCAR </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-text-field dense v-model="search" append-icon="mdi-magnify" label="BUSCAR" single-line hide-details />
                </v-col>
            </v-card-title>
            <v-card-text>
                <v-row dense>
                    <v-col cols="12">
                        <v-data-table multi-sort :search="search" :headers="headers" :items="_listaGastos" class="elevation-1" :items-per-page="20">
                            <template v-slot:[`item.action`]="{ item }">
                                <!-- <v-icon small @click="editItem(item)" color="green">mdi-pencil</v-icon> -->

                                <v-icon small @click="deleteItem(item)" color="primary">mdi-delete</v-icon>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- <v-dialog v-model="openModalProveedor">
                <form autocomplete="nope" data-vv-scope="form-1" @submit.prevent="addProveedor('form-1')">
                    <v-card>
                        <v-card-title class="grey lighten-4">
                            <b>AGREGAR PROVEEDOR</b>
                            <v-spacer />
                        </v-card-title>
                        <v-card-text>
                            <v-row dense align="center">
                                <v-col cols="12" sm="2">
                                    <v-autocomplete
                                        v-model="formProv.idTipoDocumento"
                                        v-validate="'required'"
                                        :items="tipoDocumento"
                                        item-text="text"
                                        placeholder="DNI"
                                        item-value="id"
                                        :error-messages="errors.collect('formProv.idTipoDocumento')"
                                        label="Tipo Documento"
                                        data-vv-name="formProv.idTipoDocumento"
                                        data-vv-as="Tipo"
                                        required
                                    />
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-text-field
                                        v-model="formProv.numeroDocumento"
                                        v-validate="'required'"
                                        data-vv-name="formProv.numeroDocumento"
                                        data-vv-as="N° Documento"
                                        :error-messages="errors.collect('formProv.numeroDocumento')"
                                        append-icon="mdi-account-search"
                                        outlined
                                        @click:append="getDatosExternal"
                                        label="N° Documento"
                                        placeholder="20606218100"
                                    />
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field
                                        v-model="formProv.rubro"
                                        v-validate="'required'"
                                        data-vv-name="formProv.rubro"
                                        data-vv-as="rubro"
                                        :error-messages="errors.collect('formProv.rubro')"
                                        label="Rubros"
                                        placeholder="Energia, luz"
                                    />
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field
                                        v-model="formProv.full_name"
                                        v-validate="'required'"
                                        data-vv-name="formProv.full_name"
                                        data-vv-as="Descripción"
                                        :error-messages="errors.collect('formProv.full_name')"
                                        label="Descripción"
                                        placeholder="INNOUT SAC"
                                    />
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field
                                        v-model="formProv.direccion"
                                        data-vv-name="formProv.direccion"
                                        data-vv-as="Direccion "
                                        :error-messages="errors.collect('formProv.direccion')"
                                        label="Direccion"
                                        placeholder="Jr jose de la mar 469"
                                    />
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field
                                        v-model="formProv.telefono"
                                        data-vv-name="formProv.telefono"
                                        data-vv-as="Celular "
                                        :error-messages="errors.collect('formProv.telefono')"
                                        label="Celular"
                                        placeholder="986193329"
                                    />
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-text-field
                                        v-model="formProv.correo"
                                        v-validate="'email'"
                                        data-vv-name="formProv.correo"
                                        data-vv-as="Email "
                                        :error-messages="errors.collect('formProv.correo')"
                                        label="Email"
                                        placeholder="hola@innout.pe"
                                    />
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-btn type="submit" :loading="loading2" dark :color="step == 1 ? 'primary' : 'orange'" block>
                                        <b>{{ step == '1' ? 'Agregar' : 'Actualizar' }}</b>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </form>
            </v-dialog>
            <v-dialog v-model="dialog3" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span>Compras Registradas</span>
                    </v-card-title>
                    <v-card-actions>
                        <v-btn color="primary" text @click="dialog3 = false"> Close </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog> -->
        </v-card>
    </v-container>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { mixins } from '@/mixins/mixin.js'
import DefaultForm from '@/utils/defaultForm'
import { format, subDays, addDays } from 'date-fns'
import ItemGastos from '../../components/gastos/ItemGastos'
import ItemPagos from '../../components/gastos/ItemPagos'

export default {
    components: {
        ItemGastos,
        ItemPagos
    },
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data() {
        return {
            fechaFiltro: [format(subDays(new Date(), 31), 'yyyy-MM-dd'), format(addDays(new Date(), 1), 'yyyy-MM-dd')],
            menu3: false,
            openItemTable: false,
            menu: false,
            itemTable: null,
            dialog3: false,
            menu2: false,
            loadingFile: false,
            file: null,
            checkbox: false,
            itemsCompra: [],
            search: '',
            loadingLista: false,
            step: 1,
            fecha: format(new Date(), 'yyyy-MM-dd'),
            showSucces: { state: false, text: 'Agregado exitosamente' },
            loading: false,
            form: DefaultForm.formCompras(),
            formProv: DefaultForm.formProveedor(),
            openModalProveedor: false,
            description: null,
            efectivo: null,
            loading2: false,
            dialog: false,
            headers: [
                { text: '', value: 'action', sortable: false },

                { text: 'fechaEmision', value: 'fechaEmision' },
                { text: 'Tip. Comprobante', value: 'nameTipoComprobante' },
                { text: 'Nom. Comprobante', value: 'numeroComprobante' },

                { text: 'Doc. Proveedor', value: 'perNumeroDocumento' },
                { text: 'Nomb. Proveedor', value: 'perNombresCompleto' },
                { text: 'Descripción', value: 'description' },
                { text: 'ImporteTotal', value: 'importe' },

                { text: 'ImporteAbonado S/.', value: 'importeAbonadoSoles' },
                { text: 'ImporteAbonado $', value: 'importeAbonadoDolares' }
            ]
        }
    },
    computed: {
        ...mapState('utilities', ['tipoPago', 'tipoBancoDestino', 'listaCategoria', 'tipoMoneda', 'tipoDocumento', 'tipoComprobante']),
        ...mapGetters('gastos', ['listaGastos']),
        ...mapState('compras', ['datos']),
        ...mapState('users', ['user']),
        ...mapGetters('proveedor', ['listaProveedor']),
        _listaGastos() {
            let newLista = []
            if (this.listaGastos.length > 0) {
                this.listaGastos.map((x) => {
                    const moneda = this.tipoMoneda.find((y) => y.id == x.idTipoMoneda)
                    const tipoComprobante = this.tipoComprobante.find((y) => y.id == x.idTipoComprobante)
                    //console.log('this.listaCategoria', this.listaCategoria)
                    //console.log(' x.id_tipo_gasto', x)
                    const tipo_gasto = this.listaCategoria.find((y) => y.id_tipo_gasto == x.id_tipo_gasto)
                    //console.log('tipo_gasto', tipo_gasto)
                    const parseToX = JSON.parse(x.itemGastos)
                    //console.log('parseToX', parseToX)
                    newLista.push({
                        ...x,
                        tipo_gasto: tipo_gasto.tipo_gasto,
                        nameTipoComprobante: tipoComprobante.descripcion,
                        moneda: moneda ? moneda.value : '',
                        perNumeroDocumento: x.perNumeroDocumento ? x.perNumeroDocumento : x.numeroDocumento,
                        perNombresCompleto: x.perNombresCompleto ? x.perNombresCompleto : parseToX[0].razonSocial
                    })
                })
            }
            //console.log('newLista', newLista)
            return newLista
        },
        id_tipo_gasto() {
            return this.datos.id_tipo_gasto
        },
        fechaAdd() {
            return this.datos.fechaEmision
        },
        idTipoPago() {
            return this.datos.tipoPago
        }
    },
    watch: {
        id_tipo_gasto(val) {
            if (val) {
                ////console.log("listaCategoria ", this.listaCategoria, val);
                const value = this.listaCategoria.find((x) => x.id_tipo_gasto == val)
                ////console.log("value : ", value);
                this.datos.description = value.tipo_gasto
            }
        },
        idTipoPago(val) {}
    },
    async created() {
        //console.log('datos', this.datos)
        this.$store.commit('mainUI/OPEN_BAR', {
            state: false
        })
        await this.$store.dispatch('pagos/tipoCambio')

        const compras = DefaultForm.formCompras()
        //console.log('compras ', compras)
        this.$store.commit('compras/UPDATE_COMPRA', compras)
        this.$store.commit('compras/UPDATE_LISTA_PAGOS', [])
        await this.postInitCompra()
        await this.getLista()
        await this.getListaProveedor(false)
        //console.log('this._listaGastos', this._listaGastos)
    },

    methods: {
        ...mapActions({
            handlePost: 'compras/sendPurchase'
        }),
        async upload() {
            //console.log('this.file_', this.file)

            this.loadingFile = true
            await this.handlePost({ file: this.file, user: this.user })
            this.loadingFile = false
            this.dialog3 = true
            const compras = DefaultForm.formCompras()
            this.$store.commit('compras/UPDATE_COMPRA', compras)
            this.$store.commit('compras/UPDATE_LISTA_PAGOS', [])
            this.file = []

            await this.getLista()
            await this.postInitCompra()
        },
        async postInitCompra() {
            await this.$store.dispatch('compras/postInitCompras')
        },
        async addProveedor(name) {
            this.$validator
                .validateAll(name)
                .then(async (result) => {
                    if (result) {
                        this.loading2 = true
                        // //console.log(this.listaCategoria);
                        // //console.log(detalle);
                        //console.log('start _provee')
                        const resultProv = await this.$store.dispatch('proveedor/createCliente', this.formProv)
                        this.datos.idProveedor = resultProv.id
                        this.formProv = DefaultForm.formProveedor()
                        await this.getListaProveedor(true)
                        //console.log('start _provee')
                        this.loading2 = false
                        this.openModalProveedor = false
                    }
                })
                .catch(() => {
                    this.loading2 = false
                })
        },
        openItem(item) {
            this.openItemTable = true
            this.itemTable = item
        },
        // async editItem(item) {
        //     //console.log('item ', item)
        //     //this.datos = item
        //     const itemGastos = item.itemGastos ? JSON.parse(item.itemGastos) : []
        //     this.itemsCompra = itemGastos
        //     this.$store.commit('compras/UPDATE_COMPRA', {
        //         ...item,
        //         itemGastos
        //     })

        //     await this.$store.dispatch('compras/listaPagosById')

        //     this.step = 2
        //     window.scrollTo(0, 0)
        // },
        async deleteItem(item) {
            await this.$store.dispatch('gastos/deleteGasto', { id: item.id })
            this.$store.commit('mainUI/OPEN_BAR', {
                state: true,
                text: 'Eliminado satisfactoriamente'
            })
            await this.getLista()
        },
        async addGastos(name) {
            this.$validator
                .validateAll(name)
                .then(async (result) => {
                    if (result) {
                        this.loading = true

                        let importeTotal = 0
                        let items = []
                        this.itemsCompra.map((x) => {
                            importeTotal += parseFloat(x.importe)
                            items.push({ description: x.description, importe: x.importe, unidades: x.unidades, idTipoMoneda: x.idTipoMoneda })
                        })
                        const body = {
                            ...this.datos,
                            importe: importeTotal,
                            itemGastos: items,
                            status: 1
                        }
                        let mensaje = this.step == 1 ? 'Agregado satisfactoriamente' : 'Actualizado satisfactoriamente'
                        await this.$store.dispatch('gastos/updateGastoxId', body)

                        this.step = 1
                        this.$store.commit('mainUI/OPEN_BAR', {
                            state: true,
                            text: mensaje
                        })

                        this.fnItemsCompra([])
                        await this.getLista()
                        this.$store.commit('compras/UPDATE_LISTA_PAGOS', [])

                        this.$store.commit('compras/RESET_COMPRA', {})
                        await this.postInitCompra()

                        this.loading = false
                    }
                })
                .catch(() => {
                    this.$store.commit('mainUI/OPEN_BAR', {
                        state: true,
                        text: 'Hubo un error en los datos'
                    })
                    this.loading = false
                })
        },
        async getLista() {
            this.loadingLista = true
            await this.$store.dispatch('gastos/getLista', { fechaFiltro: this.fechaFiltro })
            this.loadingLista = false
            this.menu3 = false
        },
        async getListaProveedor(payload) {
            await this.$store.dispatch('proveedor/getLista', payload)
        },
        fnItemsCompra(val) {
            this.itemsCompra = val
        },
        async getDatosExternal() {
            //console.log('this.formProv.numeroDocumento.length ', this.formProv.numeroDocumento.length)
            if (this.formProv.numeroDocumento.length >= 8) {
                try {
                    this.$store.commit('mainUI/OPEN_MODAL', {
                        state: true,
                        text: 'Buscando en Reniec/Sunat',
                        color: 'green'
                    })
                    const resultado = await this.$store.dispatch('proveedor/getClienteExternal', {
                        idTipoDocumento: this.formProv.idTipoDocumento,
                        numeroDocumento: this.formProv.numeroDocumento
                    })
                    //console.log('resultado ', resultado)
                    if (resultado.id) {
                        //console.log('find mysql')
                        await this.getListaProveedor(true)

                        /* se encuentra en la db */
                        this.formProv.id = resultado.idProveedor || ''
                        this.formProv.full_name = resultado.full_name || ''
                        this.formProv.distrito = resultado.distrito || ''
                        this.formProv.departamento = resultado.departamento || ''
                        this.formProv.provincia = resultado.provincia || ''
                        this.formProv.direccion = resultado.direccion || ''
                        this.datos.idProveedor = resultado.idProveedor
                        this.openModalProveedor = false
                    } else {
                        this.formProv = {
                            ...this.formProv,
                            ...resultado
                        }
                    }
                    //console.log('this.formProv ', this.formProv)

                    this.$store.commit('mainUI/OPEN_MODAL', { state: false })
                } catch (error) {
                    this.$store.commit('mainUI/OPEN_MODAL', { state: false })

                    //console.log('error ', error)
                }
            }
        }
    }
}
</script>
